import React from 'react';
import Hero from './components/Hero';
import Work from './components/Work';

function App() {
  return (
    <React.Fragment>
      <div className="min-h-screen flex flex-col">
        <div className='w-4/5 sm:w-5/6 mx-auto md:w-2/3 max-w-screen-md'>
          <Hero />
          <Work />
        </div>
      </div>
    </React.Fragment >
  );
}

export default App;

import { createClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react"
import { Database } from "../utils/supabase";

const supabase = createClient<Database>(
    process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL as string,
    process.env.REACT_APP_SUPABASE_SERVER_ROLE_SECRET as string
)


const Library = () => {

    const [albums, setAlbums] = useState<any[] | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            // await fetch('/api/sync-albums')
            let { data: albums } = await supabase
                .from("albums")
                .select("*")
            // .order("release_date", { ascending: false })
            // .order("name", { ascending: false })
            if (albums) setAlbums(albums)
        }
        fetchData()
    }, [])
    const formatArtworkUrl = (artworkUrl: string, format = "jpeg") => {
        const name = `512x512bb.${format}`
        let artwork = artworkUrl
            .replace("{w}x{h}bb.jpeg", `${name}`)
            .replace("{w}x{h}bb.jpg", `${name}`)
        return artwork
    }


    return (
        albums && <a href={"https://open.spotify.com/playlist/3rwxxtNUzKoajEVQrfI1VL"} target="blank" className="shadow transition-all duration-300 bg-white dark:bg-gray-900 flex-1 rounded-2xl h-32 sm:h-60 relative select-none cursor-pointer overflow-hidden">
            <div style={{
                gridTemplateAreas: `
                    ".       album4  album5  album6  album2  album2"
                    ".       album7  album1  album1  album2  album2"
                    "album3  album3  album1  album1  album8  .     "
                    "album3  album3  album9  album10 album11 .     "`}}
                className="grid gap-2 w-[160%] ml-[-30%] mt-[-3.8rem] grid-cols-[repeat(6,1fr)] grid-rows-[repeat(4,1fr)] transition-transform duration-300 text-[0] sm:mt-[-3.8rem]">
                {albums.slice(0, 11).map((album, index) => (
                    <picture
                        key={album.id}
                        style={{ gridArea: `album${index + 1}`, fontSize: 0 }}
                    >
                        <source
                            srcSet={formatArtworkUrl(album.artwork_url, "webp")}
                            type="image/webp"
                        ></source>
                        <img
                            className="w-full rounded-md"
                            src={formatArtworkUrl(album.artwork_url)}
                            alt={album.name}
                        ></img>
                    </picture>

                ))}
            </div>

            <div className="absolute bottom-0 right-0 left-0 h-32 bg-gradient-to-b from-transparent via-transparent to-gray-900">
                <div className="absolute bottom-6 left-10 text-white font-semibold text-md leading-5 flex items-center">
                    <svg className="w-4 h-4 mr-2 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 11.5V1s3 1 3 4m-7-3H1m9 4H1m4 4H1m13 2.4c0 1.325-1.343 2.4-3 2.4s-3-1.075-3-2.4S9.343 10 11 10s3 1.075 3 2.4Z" />
                    </svg> Selected
                </div>
            </div>
        </a>
    )
}

export default Library
import { GitHub } from "react-feather";
import cinemap from "../images/cinemap.png"
const CineMap = () => {
    const handleButtonClick = (isGitHub: boolean) => {
        window.open(isGitHub ? 'https://github.com/gflynn2049/CineMap' : "https://cinemap.gflynn2049.vercel.app/", '_blank');
    };


    return (
        <div className="flex flex-row gap-4 p-3 md:p-4 block h-full w-full overflow-hidden transition-all duration-300 bg-gray-100 border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 rounded-2xl">
            <img src={cinemap} alt={"cinemap"} onClick={() => handleButtonClick(false)} className="shadow h-full rounded-xl cursor-pointer" />
            <div className="flex flex-col relative justify-center">
                <a href="https://cinemap.gflynn2049.vercel.app/" target="blank" className="absolute top-0 w-fit cursor-pointer hover:underline font-semibold text-xl sm:text-3xl dark:text-gray-100">
                    CineMap
                </a>
                <div className="mt-2 sm:mt-4 lg:mt-5 text-xs sm:text-base leading-4 text-gray-600 dark:text-gray-400 overflow-hidden line-clamp-2 sm:line-clamp-3 md:line-clamp-4">An interactive map showcasing IMAX and Dolby Cinema theaters around the world, with information on their respective facilities and configurations, such as screen sizes and projector models.</div>

                <div onClick={() => handleButtonClick(true)} className="font-medium text-gray-700 mr-3 dark:text-gray-400 dark:hover:text-gray-300 hover:text-black text-xs sm:text-sm underline transition gap-2 absolute right-0 bottom-0 w-fit cursor-pointer text-center inline-flex items-center">
                    <GitHub size={15} />
                    Repo
                </div>
            </div>
        </div>
    )
}
export default CineMap
import CineMap from "./CineMap"
import Library from "./Library"
import NowPlaying from "./NowPlaying"

const Work = () => {
    return <div className="mb-20 flex flex-col gap-6 lg:gap-10">
        <div className="h-32 sm:h-60">
            <CineMap />
        </div>
        <div className="flex flex-col sm:flex-row gap-6 lg:gap-10">
            <NowPlaying />
            <Library />
        </div>
    </div>
}
export default Work
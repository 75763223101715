import React, { useEffect, useState } from "react"
import Color from "color"
import { Track } from "../Types"
import { createClient } from "@supabase/supabase-js"
import { Database } from "../utils/supabase"


const supabase = createClient<Database>(
    process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL as string,
    process.env.REACT_APP_SUPABASE_SERVER_ROLE_SECRET as string
)

const NowPlaying = (props: {}) => {

    const [nowPlaying, setNowPlaying] = useState<Track | null>(null)
    const [isPlaying, setIsPlaying] = useState<boolean | null>(null)


    useEffect(() => {
        const fetchNowPlayingInDb = async () => {
            await fetch('/api/sync-now-playing')
        }
        fetchNowPlayingInDb()

        const intervalId = setInterval(fetchNowPlayingInDb, 5000);
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    })

    useEffect(() => {
        const updateNowPlayingInDb = async () => {
            try {
                let { data: spotify_now_playing } = await supabase
                    .from("spotify_now_playing")
                    .select("*")

                if (spotify_now_playing) {
                    const track = spotify_now_playing[0]
                    setNowPlaying(track)
                    const color = Color(track.color)
                    setBackground(
                        `linear-gradient(to top, ${color.hsl()}, ${color.lighten(0.36).hsl()})`
                    )
                }
            } catch (error) {
                console.log('Error calling API:', error);
            }
        };

        updateNowPlayingInDb();

        const intervalId = setInterval(updateNowPlayingInDb, 5000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);

    }, []);


    const [background, setBackground] = useState<string | null>(null)


    useEffect(() => {
        if (!nowPlaying) return
        const checkingInterval = setInterval(() => {
            const newValue = new Date().getTime() - nowPlaying.timestamp < 30000
            if (newValue !== isPlaying) setIsPlaying(newValue)
        }, 1000)
        return () => {
            clearInterval(checkingInterval)
        }
    }, [nowPlaying, setIsPlaying])


    return (
        <React.Fragment>
            <div style={background ? { background: background } : {}} className={(`${nowPlaying ? '' : 'animate-pulse bg-gray-100 dark:bg-gray-800'}`) + `shadow p-3 md:p-4 w-full h-32 sm:w-60 sm:h-60 rounded-2xl flex hover:brightness-110 transition`}>
                {nowPlaying ?
                    <a href={`https://open.spotify.com/track/${nowPlaying.spotify_id}`} target="blank" className="relative gap-3 sm:gap-0 flex sm:flex-col t-0 b-0 w-full h-full overflow-hidden abosulte text-white select-none cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="absolute top-0 right-0 opacity-60" viewBox="0 0 16 16">
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.669 11.538a.498.498 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686zm.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288z" />
                        </svg>

                        <img style={{ aspectRatio: "1/1" }} src={nowPlaying.artwork_url || ''} alt={nowPlaying.album || "album cover"} className="h-full sm:w-2/3 rounded-xl shadow" />
                        <div className="flex flex-col h-full justify-evenly">
                            <div className="flex sm:flex-grow sm:items-center">
                                <div className="font-semibold h-max-content sm:text-sm sm:mt-2 text-lg mr-4 sm:mr-0 line-clamp-2 sm:line-clamp-2 overflow-hidden">
                                    {nowPlaying.artist} - {nowPlaying.name}
                                </div>
                            </div>
                            <div className="text-xs sm:text-sm font-medium text-white opacity-60">{isPlaying ? "Now playing" : "Recently played"}</div>
                        </div>
                    </a>
                    : <></>}
            </div >
        </React.Fragment>
    )
}
export default NowPlaying
import avatar from "../images/avatar.png"
const Avatar = () => {
    return (
        <div className="flex-shrink-0 w-20 h-20 sm:w-24 sm:h-24 border-4 border-solid border-gray-100 hover:border-gray-200 dark:hover:border-gray-500 transition dark:border-gray-700 rounded-full flex items-center justify-center">
            <div style={{
                background: `linear-gradient(to top left, hsl(220, 100%, 60%) 0%, hsl(250, 100%, 82%) 100%)`, width: `90%`, height: '90%'
            }} className="rounded-full flex items-center justify-center">
                <img
                    style={{ width: `100%`, height: '100%' }}
                    src={avatar}
                    alt="avatar"
                    height={54}
                    width={54}
                />
            </div>
        </div>
    )
}

export default Avatar
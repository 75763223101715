import Avatar from "./Avatar"
import { GitHub, Mail, Instagram, Book } from "react-feather"
import SocialAppButton from "./SocialAppButton"

const Hero = () => {

    return (
        <section className="mt-24 sm:mt-20" >
            <Avatar />
            <h1 className="ml-1 mt-3 sm:mt-4 lg:mt-6 flex-grow text-3xl sm:text-5xl font-bold tracking-tight dark:text-white">
                hi, i'm gflynn2049.
            </h1>

            <blockquote className="mt-5 md:mt-9 ml-0.5 p-3 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
                <p className="text-sm sm:text-base sm:text-lg leading-5 sm:leading-6 text-gray-900 dark:text-white"><span style={{ fontFamily: "Georgia" }}>Georgia</span> was designed by Matthew Carter for clarity at small sizes on computer monitors: it features a large x-height and its thin strokes are relatively thick.</p>
                <div className="mt-2 text-xs text-gray-400 dark:text-gray-400">Paul McNeil, The Visual History of Type</div>
            </blockquote>
            <p className="flex my-2 sm:my-4 lg:my-6 gap-3 font-medium text-lg text-gray-800 md:text-lg lg:text-2xl dark:text-gray-400">

                <SocialAppButton
                    link="https://blog.gflynn2049.vercel.app/"
                    target="blank"
                >
                    <Book size={26} />
                </SocialAppButton>


                <SocialAppButton
                    link="https://github.com/gflynn2049"
                    target="blank"
                >
                    <GitHub size={26} />
                </SocialAppButton>

                <SocialAppButton
                    link="mailto:gflynn2049@gmail.com"
                    target="blank"
                >
                    <Mail size={26} />
                </SocialAppButton>

                <SocialAppButton
                    link="https://www.instagram.com/gflynn2049/"
                    target="blank"
                >
                    <Instagram size={26} />

                </SocialAppButton>
            </p>
        </section>

    )
}

export default Hero
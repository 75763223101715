import React, { useState, ReactNode } from "react";

interface ComponentProps {
    children: ReactNode;
    link: string;
    toolTip?: string;
    target?: string;
}

const SocialAppButton: React.FC<ComponentProps> = ({
    children,
    link,
    target,
}) => {

    return (
        <div className="relative">
            <a
                href={link}
                target={target}
                className="block text-gray-300 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300 p-3 flex items-center justify-center rounded-full transition duration-200 cursor-pointer hover:bg-gray-100 hover:text-gray-900"
            >
                {children}
            </a>
        </div>
    );
};

export default SocialAppButton;
